angular.module('enervexSalesappApp').controller('BreakCtrl', function($scope, $uibModalInstance, Util, paint, originalSection, newSection1, newSection2) {
	$scope.cancel = function() {
		var modalInstance = $uibModalInstance.close();
		paint();
	}

	var originalSection = originalSection
	$scope.newVent1 = newSection1
	$scope.newVent2 = newSection2
	$scope.multiFits = $scope.fittings;

	function setDims() {
		var DimX = originalSection.DimX / 2
		var DimY = originalSection.DimY / 2
		var DimZ = originalSection.DimZ / 2
		$scope.newVent1.DimX = DimX
		$scope.newVent1.DimY = DimY
		$scope.newVent1.DimZ = DimZ
		$scope.newVent2.DimX = DimX
		$scope.newVent2.DimY = DimY
		$scope.newVent2.DimZ = DimZ
	}
	setDims()


	// change fit on first model, also update other model
	$scope.$watch('newVent1.Fit2', function(v){
	  $scope.newVent2.Fit1 = v;
	});

	function _hasErrors() {
		$scope.errors = {}
		_.each(["DimX", "DimY", "DimZ","VentType", "Shape", "Fit1", "Fit2", "Dim1", "Dim2"], function(prop){
			$scope.errors[prop] = hasError(prop)
		})
		// console.log($scope.errors)
	}
	$scope.checkErrors = function(prop){
		_hasErrors()
	}
	function hasError(prop){
		if ($scope.selectedConnections.length > 1){
			return false;
		}
		var v = $scope.selectedConnections[0];
		var direction = Util.direction(v, $scope.design)
		if (prop == "DimX") {
			if (v.DimX == 0 && direction.x){
				console.log("errored X based on ", _.pick(v,"DimX", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if (prop == "DimY"){
			if (v.DimY == 0 && direction.y){
				console.log("errored Y based on ", _.pick(v,"DimY", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if ($scope.design.viewMode == "3D" && prop == "DimZ"){
			if (v.DimZ == 0 && direction.z){
				console.log("errored Z based on ", _.pick(v,"DimZ", "X1", "X2","Y1","Y2","Z1","Z2"))
				return true;
			}
		}
		if (prop == "Dim1") {
			if ($scope.vent.Optimize == "No" && (!$scope.vent.Dim1 || $scope.vent.Dim1 == 0 || $scope.vent.Dim1 == "0")){
				return true
			}
		}
		if (prop == "Dim2"){
			if ($scope.vent.Optimize == "No" && (!$scope.vent.Dim2 || $scope.vent.Dim2 == 0 || $scope.vent.Dim2 == "0") && !($scope.vent.Shape == "ROU" || $scope.vent.Shape == "SQU") ){
				return true
			}
		}
		if (prop == "VentType"){
			var valid = _.find($scope.ventMaterials, function(vm){
				return vm.code == $scope.vent.VentType;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Shape"){
			var valid = _.find($scope.cmbShape.options, function(vm){
				return vm.value == $scope.vent.Shape;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Fit1"){
			var valid = _.find($scope.fitting1.options, function(vm){
				return vm.code == $scope.vent.Fit1;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		if (prop == "Fit2"){
			var valid = _.find($scope.fitting2.options, function(vm){
				return vm.code == $scope.vent.Fit2;
			})
			if (valid){
				return false
			} else {
				return true
			}
		}
		return false
	}

	$scope.getUnits = Util.getUnits;
	$scope.dimXText = Util.dimXText;
	$scope.dimYText = Util.dimYText;



});
